import React, { useEffect, useState } from "react"
import InputField from "../../../../components/Yard/InputField/InputField"
import {
  PlusCircleOutlined,
  SaveFilled,
  EditFilled,
  DeleteOutlined,
  UndoOutlined,
  CloseOutlined,
} from "@ant-design/icons"
import {
  Card,
  Table,
  Select,
  Button,
  InputNumber,
  Input,
  Divider,
  Row,
  Col,
  message,
  Spin,
} from "antd"
import API from "../../../../api"
import { connect } from "react-redux"
import Add from "../../../../images/yard_assets/add_service_icon.svg"
import Addd from "../../../../images/yard_assets/add_service_icond.svg"
import Delete from "../../../../images/yard_assets/Delete icon.svg"
import Edit from "../../../../images/yard_assets/edit icon.svg"

import {
  IndustryType,
  RAIL_CAR,
  TextCapitalize,
} from "../../../../utils/helper"
const { Option } = Select
const ServiceSelect = props => {
  const {
    fetchServiceDetailsPost,
    addSubTicket,
    editQuantity,
    deletePerforma,
    undoPerforma,
  } = API
  const [btnDisable, setBtnDisable] = useState(false)
  const [invoiceData, setInvoiceData] = useState(null)
  const [addBtnDisable, setaddBtnDisable] = useState(false)
  const [itemAddDiable, setItemAddDisable] = useState(true)
  const [service, setService] = useState([])
  const [subService, setSubservice] = useState([])

  useEffect(() => {
    props.ticketDetailsData &&
      props.ticketDetailsData.map(item => {
        setProformaData(item.sub_tickets)
      })
  }, [props.ticketDetailsData])
  const setProformaData = res => {
    const _data = res
    const data = localStorage.getItem("data")
    // let jsonParse = JSON.parse (data);
    const invoiceArr = []

    if (_data.length === 0) {
      invoiceArr.push({
        services: { param_description: "" },
        estimate_price: "",
      })
    } else {
      _data.map((item, i) => {
        const serviceMstid = service.filter(id => id.id === item.service_mst_id)
        const filter = props.serviceData.filter(
          ele =>
            item.service_id === ele.service_id &&
            ele.service_group_id === props.parent_id
        )
        if (filter.length !== 0) {
          invoiceArr.push({
            ...item,
            key: i + 1,
            name: TextCapitalize(serviceMstid[0]?.name),
            quantity: item.quantity,
            edit: false,
          })
        }
      })
    }
    setaddBtnDisable(false)
    setInvoiceData(invoiceArr)
  }
  const addMore = () => {
    setBtnDisable(true)
    setaddBtnDisable(true)
    setItemAddDisable(true)
    const arr = [...invoiceData]
    arr.push({ services: { param_description: "" }, estimate_price: "" })
    setInvoiceData(arr)
  }

  useEffect(() => {
    setaddBtnDisable(true)
  }, [])

  useEffect(() => {
    filterData(props.serviceData)
  }, [props.serviceData])

  useEffect(() => {
    props.ticketDetailsData &&
      props.ticketDetailsData.map(item => {
        setProformaData(item.sub_tickets)
      })
  }, [props.ticketDetailsData])
  const filterData = val => {
    let service = []
    val.forEach(function (item) {
      let _service = service.filter(x => x.value == item.service_id)
      if (_service.length <= 0 && item.service_group_id === props.parent_id) {
        service.push({
          parent_id: item.service_group_id,
          name: item["service_name.param_description"],
          value: item.service_id,
          id: item.id,
          tax: item.tax,
          price: item.price,
        })
      }
    })
    setService(service)
  }
  const onSelectService = (e, i, _item) => {
    const arr = [...invoiceData]
    let child = []
    const ids = new Set(arr.map(({ service_mst_id }) => service_mst_id))
    let selectedRows = child.filter(({ id }) => !ids.has(id))
    setItemAddDisable(false)
    arr.map((item, index) => {
      if (index === i) {
        service &&
          service.map(ele => {
            console.log(ele)
            if (ele.id === e) {
              item.service_id = ele.value
              item.id = e
              item.estimate_price = ele.price
              item.tax = ele.tax
            }
          })
      }
    })
    setInvoiceData(arr)
  }
  // const onSelectSubService=(e,i,_item)=>{
  //   const arr=[...invoiceData]
  //   setItemAddDisable(false);
  //   arr.map((item,index)=>{
  //       if(index===i){
  //       item.subService&&item.subService.map((ele)=>{
  //           if(e===ele.id){
  //             item.sub_service_id=ele.value
  //             item.id=e;
  //             item.estimate_price = ele.price
  //             item.tax = ele.tax
  //           }
  //       })
  //   }
  //   })
  //   setInvoiceData(arr)
  // }
  const AddSubTicket = (i, item) => {
    setaddBtnDisable(true)
    setItemAddDisable(true)
    const serviceMstid = service.filter(id => id.id === item.id)
    const arr = [...invoiceData]
    let ticketData = {
      id: item.id,
      ticket_id: props.data?.ticket_id,
      status: props.data.ticket_status?.param_value,
      price: item.estimate_price,
      tax: item.tax,
      service_id: item.service_id,
      quantity: item.quantity,
      coordinates: [0.0, 0.0],
      ...(IndustryType() === RAIL_CAR && { remark: item?.description }),
    }
    if (item.sub_service_id) {
      ticketData = { ...ticketData, sub_service_id: item.sub_service_id }
    }
    addSubTicket(ticketData)
      .then(res => {
        const data = res.data.data
        setaddBtnDisable(false)
        setItemAddDisable(false)
        if (res.data.success) {
          message.success(res.data.message)
          arr.splice(i, 1, { ...data, name: serviceMstid[0]?.name })
          checkIncomplete(arr)
          setInvoiceData(arr)
        } else {
          message.error(res.data.message)
        }
      })
      .catch(err => {
        setaddBtnDisable(false)
        setItemAddDisable(false)
      })
  }
  const checkIncomplete = arr => {
    const filter = arr.filter(item => !item.ticket_id)
    if (filter.length === 0) {
      setBtnDisable(false)
      setaddBtnDisable(false)
    } else {
      setBtnDisable(true)
      setaddBtnDisable(true)
    }
  }
  const deleteTicket = (i, item, ticket_id) => {
    const arr = [...invoiceData]
    if (ticket_id) {
      deletePerforma(ticket_id, { coordinates: [0.0, 0.0] })
        .then(res => {
          item.deleted = true
          checkIncomplete(arr)
          setInvoiceData(arr)
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      arr.splice(i, 1)
      checkIncomplete(arr)
      setInvoiceData(arr)
    }
  }
  const editTicket = (i, item, ticket_id) => {
    const arr = [...invoiceData]
    arr.map((_item, index) => {
      if (index === i) {
        _item.edit = true
      }
    })
    setInvoiceData(arr)
  }
  const updateTicket = (i, item, ticket_id) => {
    const param = {
      id: ticket_id,
      quantity: item.quantity,
      ...(IndustryType() === RAIL_CAR && { remark: item?.description }),
    }
    editQuantity(param)
      .then(res => {
        const arr = [...invoiceData]
        arr.map((_item, index) => {
          if (index === i) {
            if (res.data.success) {
              _item.edit = false
              message.success({ content: res.data.message, duration: 2 })
            } else {
              message.error({ content: res.data.message, duration: 2 })
            }
          }
        })
        setInvoiceData(arr)
      })
      .catch(err => {
        message.error({
          content: `${err?.response?.data.message}`,
          duration: 2,
        })
      })
  }
  const undoTicket = (i, item, ticket_id) => {
    const arr = [...invoiceData]
    if (ticket_id) {
      const arr = [...invoiceData]
      undoPerforma(ticket_id, { coordinates: [0.0, 0.0] }).then(res => {
        item.deleted = false
        setInvoiceData(arr)
      })
    } else {
      arr.splice(i, 1)
      setInvoiceData(arr)
    }
  }
  const subServicePlaceHolder = value => {
    const placeholder = value && value.map(item => item.placeholder)
    if (placeholder[0]) {
      return placeholder[0]
    } else {
      return "Select Sub Service"
    }
  }
  const onChangeQuantity = (e, i) => {
    const _invoiceData = [...invoiceData]
    _invoiceData.map((item, index) => {
      if (index === i) {
        if (e === 0) {
          item.quantity = 1
        } else {
          item.quantity = e
        }
      }
    })
    setInvoiceData(_invoiceData)
  }
  const onChangeRemark = (e, i) => {
    const _invoiceData = [...invoiceData]
    _invoiceData.map((item, index) => {
      if (index === i) {
        item.description = e.target.value
      }
    })
    setInvoiceData(_invoiceData)
  }

  return (
    <div className="amount">
      <div
        className="amount_wash_ser_change"
        style={{ marginBottom: addBtnDisable ? "0px" : "15px" }}
      >
        <h3>{props.name}</h3>
        {/* <Button disabled={addBtnDisable} onClick={()=>addMore()}>+Add Service</Button> */}
        {addBtnDisable ? (
          <div className="amount_wash_ser_btn_dis">
            <img src={Addd} alt="icon" />
            <span>add service</span>
          </div>
        ) : (
          <div className="amount_wash_ser_btn" onClick={() => addMore()}>
            <img src={Add} alt="icon" />
            <span>add service</span>
          </div>
        )}
      </div>
      <div className="amount_name">
        {invoiceData && invoiceData?.length !== 0 ? (
          <table
            id="services"
            style={{
              width: "100%",
              padding: "10px 0px",
              background: "#F6F6F6",
              borderTop: "1px solid lightgrey",
              marginBottom: "13px",
            }}
          >
            <tr style={{ height: 32 }}>
              <th style={{ width: "60%", paddingLeft: "27px" }}>Item</th>
              <th style={{ width: "10%", textAlign: "left" }}>Quantity</th>
              <th style={{ width: "30%", textAlign: "end" }}></th>
            </tr>
            {invoiceData.map((item, i) => (
              <>
                <tr style={{ height: 110 }}>
                  <td>
                    {!item.ticket_id ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            marginRight: "5px",
                            marginLeft: "17px",
                          }}
                        >
                          <Select
                            style={{ marginLeft: 10, width: "200px" }}
                            value={item.id}
                            placeholder="Select Service"
                            onChange={e => onSelectService(e, i, item)}
                          >
                            <Option value="" disabled>
                              Select Service
                            </Option>
                            {service &&
                              service.map(ele => (
                                <Option value={ele.id}>{ele.name}</Option>
                              ))}
                          </Select>

                          {/* {item?.subService&&<Select
                      style={{marginLeft:10,marginTop:"-23px"}}
                      value={item.id}
                        placeholder={subServicePlaceHolder(item.subService)}
                        onChange={(e) =>onSelectSubService(e,i,item)}
                      >
                         <Option value="" disabled >
                         {subServicePlaceHolder(item.subService)}
                            </Option>
                        {item.subService.map (ele =>(
                            <Option value={ele.id}>
                              {ele.name}
                            </Option>
                          ))}
                      </Select>} 
                       */}
                        </div>
                      </>
                    ) : (
                      <div className="wash_item_labor">{item.name}</div>
                    )}
                  </td>

                  <td>
                    {" "}
                    {!item.ticket_id || item.edit ? (
                      <div className="wash_item_labor">
                        <InputNumber
                          onChange={e => onChangeQuantity(e, i)}
                          value={item.quantity}
                        />
                      </div>
                    ) : (
                      <div className="wash_item_labor">{item.quantity}</div>
                    )}{" "}
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginLeft: "5px",
                      }}
                    >
                      {(item.deleted !== undefined || item.deleted !== false) &&
                      !item.ticket_id &&
                      itemAddDiable ? (
                        <div
                          className="wash_ticket_save_dis"
                          style={{ background: "grey", cursor: "not-allowed" }}
                        >
                          save
                        </div>
                      ) : (
                        (item.deleted !== undefined ||
                          item.deleted !== false) &&
                        !item.ticket_id && (
                          <div
                            className="wash_ticket_save_dis"
                            onClick={() => AddSubTicket(i, item)}
                          >
                            save
                          </div>
                        )
                      )}
                      {!item.deleted && item.ticket_id && (
                        <>
                          {" "}
                          {!item.edit ? (
                            <div
                              style={{
                                marginTop: "10px",
                                marginRight: "15px",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                src={Edit}
                                alt="edit"
                                onClick={() =>
                                  editTicket(i, item, item.ticket_id)
                                }
                              />
                            </div>
                          ) : (
                            <div
                              className="wash_ticket_save_dis"
                              onClick={() =>
                                updateTicket(i, item, item.ticket_id)
                              }
                            >
                              update
                            </div>
                          )}
                        </>
                      )}
                      {item.deleted == true ? (
                        <div
                          className="wash_ticket_save_dis"
                          style={{ background: "#FF6B72" }}
                          onClick={() => undoTicket(i, item, item.ticket_id)}
                        >
                          deleted
                        </div>
                      ) : (
                        <div
                          style={{
                            marginTop: "10px",
                            marginRight: "15px",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={Delete}
                            alt="delete"
                            onClick={() =>
                              deleteTicket(i, item, item.ticket_id)
                            }
                          />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
                {IndustryType() == RAIL_CAR && (
                  <tr>
                    <div
                      style={{
                        marginLeft: "28px",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    >
                      <Input
                        placeholder="Remarks"
                        value={item?.description}
                        onChange={e => onChangeRemark(e, i)}
                      />
                    </div>
                  </tr>
                )}
              </>
            ))}
          </table>
        ) : (
          <div style={{ height: 0 }}></div>
        )}
      </div>
    </div>
  )
}

export default ServiceSelect
